import actions from "./actions";

const initState = {
  isLoadingTask: false,
  errorMessage: false,
  successMessage: null,
  resetMessage: false,
  tasksSelector: {},
  tasks: [],
  modalActive: false,
  task: {},
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoadingTask: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoadingTask: false,
        tasks: payload.data,
        tasksSelector: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "There is a loading problem",
      };
    case actions.LOAD_TASKS_SELECTOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tasksSelector: payload.data,
      };
    case actions.LOAD_TASKS:
      return {
        ...state,
        successMessage:null,
    };  
    case actions.LOAD_TASKS_SUCCESS:
      return {
        ...state,
        tasksSelector: payload.data,
        tasks: payload.data,
      };
    case actions.SAVE_TASK:
        return {
          ...state,
          task: payload.data,
        };
    case actions.SAVE_TASK_SUCCESS:
        return {
          ...state,
          isLoadingTask: false,
          errorMessage: null,
          successMessage: 'Task has been saved successfully',
          resetMessage: !state.resetMessage,
        };
    case actions.LOAD_TASK_ERROR:
          return {
            ...state,
            isLoadingTask: false,
        }; 
    
    case actions.UPDATE_TASK:
      return {
        ...state,
        task: payload.data,
      };
      
    case actions.UPDATE_TASK_SUCCESS:
      return {
        ...state,
        isLoadingTask: false,
        successMessage: 'Task has been edited successfully',
        errorMessage: null,
        resetMessage: !state.resetMessage,
      }; 
      
    case actions.DELETE_TASK_ERROR:
        return {
          ...state,
          successMessage: null,
          resetMessage: !state.resetMessage,
          errorMessage: 'It is not possible to delete this Task!!',
    };  

    case actions.DELETE_TASK_SUCCESS:
        return {
          ...state,
          isLoadingTask: false,
          errorMessage: null,
          successMessage: 'Delete Task successfully!!',
          resetMessage: !state.resetMessage,
    };
         
    case actions.TOGGLE_HANDLE_MODAL_TASK:
      return {
        ...state,
        successMessage: null,
        task: payload.data == null ? initState.task : payload.data,
      }; 
      
      case actions.REMOVE_NOTIFICATION_MESSAGE:
        return {
          ...state,
          successMessage: null,
          resetMessage: null,
          errorMessage: null
        };  
    default:
      return state;
  }
}
