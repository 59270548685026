const DOCUMENT = "TASK_";

const actions = {
  LOAD_FROM_FIRESTORE: DOCUMENT + "LOAD_FROM_FIRESTORE",
  LOAD_FROM_FIRESTORE_SUCCESS: DOCUMENT + "LOAD_FROM_FIRESTORE_SUCCESS",
  LOAD_FROM_FIRESTORE_ERROR: DOCUMENT + "LOAD_FROM_FIRESTORE_ERROR",

  LOAD_TASKS: DOCUMENT + "LOAD_TASKS",
  LOAD_TASKS_SUCCESS: DOCUMENT + "LOAD_TASKS_SUCCESS",

  LOAD_TASKS_SELECTOR: DOCUMENT + "LOAD_TASKS_SELECTOR",
  LOAD_TASKS_SELECTOR_SUCCESS: DOCUMENT + "LOAD_TASKS_SELECTOR_SUCCESS",

  SAVE_TASK: DOCUMENT + 'SAVE_TASK',
  SAVE_TASK_SUCCESS: DOCUMENT + 'SAVE_TASK_SUCCESS',

  UPDATE_TASK: DOCUMENT + "UPDATE",
  UPDATE_TASK_SUCCESS: DOCUMENT + "UPDATE_USER_SUCCESS",

  TOGGLE_HANDLE_MODAL_TASK: DOCUMENT + "TOGGLE_TASK_HANDLE_MODAL",

  DELETE_TASK: DOCUMENT + "DELETE_TASK",
  DELETE_TASK_ERROR: DOCUMENT + "DELETE_TASK_ERROR",
  DELETE_TASK_SUCCESS: DOCUMENT + "DELETE_TASK_SUCCESS",

  REMOVE_NOTIFICATION_MESSAGE: DOCUMENT + "REMOVE_NOTIFICATION_MESSAGE",


  loadFromFireStore: () => {
    return { type: actions.LOAD_FROM_FIRESTORE };
  },

  loadFromFireStoreSuccess: (data) => ({
    type: actions.LOAD_FROM_FIRESTORE_SUCCESS,
    payload: { data },
  }),

  loadFromFireStoreError: (error) => ({
    type: actions.LOAD_FROM_FIRESTORE_ERROR,
    payload: { error },
  }),

  loadTasks: (filterTask) => ({
    type: actions.LOAD_TASKS,
    payload: { filterTask },
  }),

  loadTasksSuccess: (data) => ({
    type: actions.LOAD_TASKS_SUCCESS,
    payload: { data },
  }),

  loadTasksSelector: (filterTask) => ({
    type: actions.LOAD_TASKS_SELECTOR,
    payload: { filterTask },
  }),

  loadTasksSelectorSuccess: (data) => ({
    type: actions.LOAD_TASKS_SELECTOR_SUCCESS,
    payload: { data },
  }),

  saveTask: (data, actionName = 'insert') => ({
    type: actions.SAVE_TASK,
    payload: { data, actionName },
  }),

  saveTaskSuccess: () => ({
    type: actions.SAVE_TASK_SUCCESS,
  }),

  saveTaskError: (error) => ({
    type: actions.SAVE_TASK_ERROR,
    payload: { error },
  }),


  updateTask: (data) => ({
    type: actions.UPDATE_TASK,
    payload: { data },
  }),
  updateTaskSuccess: () => ({
    type: actions.UPDATE_TASK_SUCCESS,
  }),


  deleteTask: (data, filters) => ({
    type: actions.DELETE_TASK,
    payload: { data, filters },
  }),
  deleteTaskError: (error) => ({
    type: actions.DELETE_TASK_ERROR,
    payload: { error },
  }),

  deleteTaskSuccess: () => ({
    type: actions.DELETE_TASK_SUCCESS,
  }),


  toggleModal: (data = null) => ({
    type: actions.TOGGLE_HANDLE_MODAL_TASK,
    payload: { data },
  }),

  cleanNotificationMessage: () => ({
    type: actions.REMOVE_NOTIFICATION_MESSAGE,
  }),

};
export default actions;
