import moment from "moment";
import {getAccountData} from "../../components/library/helpers/local_storage";

const localDataName = "mateInvoice";

const getDataAccountInvoice = (newInvoice) => {
  let dataAccount = getAccountData();
  newInvoice.billFrom = dataAccount.billFrom;
  newInvoice.billFromAddress = dataAccount.address;
  newInvoice.billFromPhone = dataAccount.phone;
  newInvoice.billFromEmail =  dataAccount.email;
  newInvoice.billFromIban = dataAccount.iban;
  newInvoice.nifFrom = dataAccount.nif;
  newInvoice.companyFrom = dataAccount.companyName;
  return newInvoice;
}

const newInvoice = {
  orderStatus: "draft",
  statusPayment: "pending",
  orderDate: moment().toDate().getTime(),
  orderMonth: null,
  currency: "€",
  billTo: "",
  billToAddress: "",
  billFrom: "A-Digital",
  billFromAddress: "Carrer Punta Natí 8 Poima, Oficina A 07714 Mahón - Islas Baleares",
  billFromPhone: "+34 111 22 22 22",
  billFromEmail: "alain@a-digital.net",
  billFromIban: "ES1111111111111111111111",
  nifFrom: "Z23757575",
  companyFrom: "A-Digital",
  invoiceList: [
    {
      key: 1,
      itemName: "",
      itemType: "A",
      noTaxable: false,
      costs: 0,
      qty: 1,
      price: 0,
      createdAt: Date.now(),
    },
  ],
  taxable: 0,
  subTotal: 0,
  vatRate: 21,
  vatPrice: 0,
  totalCost: 0,
  emailSent: false,
  type: 'invoice', /* type: invoice or note */
  deleted_at: null,
};

const initialListView = {
  top: 0,
  currentInvoice: null,
  loading: false,
  selected: [],
  isRangePicker: true,
  singleResult: null,
  startDateId: "start_date_id",
  endDateId: "end_date_id",
  filter: {
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(new Date().getFullYear()+1, 0, 0),
    billToId: null,
    billToName: "",
    orderStatus: null,
    invoice_serie: null,
    invoiceSerieName: "",
    statusPayment: null,
    type: null
  }
}

export { localDataName, newInvoice, initialListView, getDataAccountInvoice };
