import { all, takeEvery, put,takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { simpleQueryFirestoreWithFilter, simpleQueryFirestoreWithFilterOrderBy, existInFirestoreDocument } from "../../components/utility/firestore.helper";
import { rsf } from '@iso/lib/firebase/firebase';
import { call } from 'redux-saga/effects';
import omit from 'lodash/omit';
import { getAccountIdPrefix } from '@iso/components/library/helpers/local_storage';
import { ConsoleSqlOutlined } from '@ant-design/icons';

const COLLECTION_NAME = 'users';
const COLLECTION_NAME_PROPERTY = 'properties';

function* getUsers(query) {
	try {
		const filters = query && query.payload.filters;
		const orderBy = query && query.payload.orderBy;
		const data = (orderBy) ? yield simpleQueryFirestoreWithFilterOrderBy(getAccountIdPrefix() + COLLECTION_NAME, filters, orderBy) :
			yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, filters);
		yield put(actions.getUsersSuccess(data));
	} catch (error) {
		console.log(error);
		yield put(actions.getUsersError(error));
	}
}

function* getUser(query) {
	try {
		const filters = query && query.payload.filters;
		const data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, filters);
		let [firstKey] = Object.keys(data);
		yield put(actions.getUserSuccess({ ...omit(data[firstKey], ['password']) }));
	} catch (error) {
		yield put(actions.getUserError(error));
	}
}


function* loadAutocompleteUsers(query) {
	try {
		const filters = query && query.payload.data;
		const data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, filters );
		yield put(actions.getUsersAutocompleteSuccess(data));
	} catch (error) {
		console.log(error);
		yield put(actions.getUsersAutocompleteError(error));
	}
}

function* saveClient({ payload }) {
	const { data, actionName } = payload;
	try {
		switch (actionName) {
			case 'update':
				yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${data.key}`, {
					...omit(data, ['key']),
				});
				break;
			default:
				yield call(rsf.firestore.addDocument, getAccountIdPrefix() + COLLECTION_NAME, data);
				break;
		}
		yield put({ type: actions.LOAD_USERS, payload: { filters: payload.filters }});
		yield put({ type: actions.LOAD_USERS_AUTOCOMPLETE, payload: { filters: payload.filters }});
		yield put( actions.saveUserSuccess());
		yield put( actions.cleanNotificationMessage());
	} catch (error) {
		yield put(actions.saveUserError(error));
	}
}

function* saveUser({ payload }) {
	const { data, actionName } = payload;
	try {
		switch (actionName) {
			case 'update':
				yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${data.key}`, {
					...omit(data, ['key']),
				});
				yield call(rsf.firestore.setDocument, `${COLLECTION_NAME}/${data.key}`, {
					...omit(data, ['key']),
				});
				yield put(actions.updateUserSuccess());
				break;
			default:
				if (getAccountIdPrefix() !== '')
					yield call(rsf.firestore.addDocument, getAccountIdPrefix() + COLLECTION_NAME, data);
				yield call(rsf.firestore.addDocument, COLLECTION_NAME, data);
				yield put(actions.saveUserSuccess());
				break;
		}
		yield put({ type: actions.LOAD_USERS, payload: { filters: payload.filters } });
		yield put(actions.cleanNotificationMessage());
	} catch (error) {
		yield put(actions.saveUserError(error));
	}
}

function* deleteUser({ payload }) {
	const { data } = payload;
	try {
		const exist = yield existInFirestoreDocument(getAccountIdPrefix() + COLLECTION_NAME_PROPERTY, {'value': data.id , 'operator': '==', 'field': 'ownerId' });;
		if(!exist.length) {
			if (getAccountIdPrefix() !== '')
				yield call(rsf.firestore.deleteDocument, getAccountIdPrefix() + COLLECTION_NAME +'/'+ data.key);
			yield call(rsf.firestore.deleteDocument, COLLECTION_NAME +'/'+ data.key)
			yield put({ type: actions.LOAD_USERS, payload: { filters: payload.filters }});
			yield put({ type: actions.LOAD_USERS_AUTOCOMPLETE, payload: { filters: payload.filters }});
		}
		else {
			yield put(actions.deleteUserError());
		}
		yield put(actions.cleanNotificationMessage());
	} catch (error) {
		yield put(actions.deleteUserError(error));
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(actions.LOAD_USERS, getUsers),
		takeEvery(actions.LOAD_USER, getUser),
		takeEvery(actions.LOAD_USERS_AUTOCOMPLETE, loadAutocompleteUsers),
		takeEvery(actions.SAVE_USER, saveUser),
		takeEvery(actions.SAVE_CLIENT, saveClient),
		takeEvery(actions.DELETE_USER, deleteUser),
	]);
}
