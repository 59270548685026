import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import theme from '@iso/config/theme/theme.config';
import AppLocale from '@iso/config/translation';

export default function AppProvider({ children }) {
  const { locale } = useSelector(state => state.LanguageSwitcher.language);
  const { themeName } = useSelector(state => state.ThemeSwitcher.changeThemes);
  const currentAppLocale = AppLocale[locale];
  //<ConfigProvider theme={{ token: { fontFamily: 'Be Vietnam, sans-serif'}, hashed: false, fontFamily: 'Be Vietnam, sans-serif' }} locale={currentAppLocale.antd}>
  return (
    <ConfigProvider theme={{ token: { fontFamily: 'TTNorms, sans-serif'}, hashed: false, fontFamily: 'TTNorms, sans-serif' }} locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}
