import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import { simpleQueryFirestoreWithFilter,existInFirestoreDocument } from "../../components/utility/firestore.helper";
import { rsf } from "@iso/lib/firebase/firebase";
import omit from "lodash/omit";
import { getAccountIdPrefix } from '@iso/components/library/helpers/local_storage';

const COLLECTION_NAME = 'tasks'; // change your collection

function* loadFromFirestore() {
  try {
    const filter = {
      field: "deleted_at",
      operator: "==",
      value: null,
    };
    let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, [filter]);
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* loadTaskSelector(query) {
  try {
    const filtersItem = query && query.payload.filterItem;
    const data = yield simpleQueryFirestoreWithFilter(
        getAccountIdPrefix() + COLLECTION_NAME,
      filtersItem
    );
    yield put(actions.loadItemsSelectorSuccess(data));
  } catch (error) {
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* loadTaks(query) {
  try {
    const filtersItem = query.payload.filterTask ? query.payload.filterTask  : null ;//&& query.payload.filterItem;
    const data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, filtersItem);
    const dataSource = [];
    //#Vuelve a crear el array de datos "dataSource" pero con un atributo key para poder realizar los updates de las tasks
    Object.keys(data).map((item, index) => {
      return dataSource.push({
        ...data[item],
        key: item,
      });
    });
    yield put(actions.loadTasksSuccess(dataSource));
    //yield put(actions.loadTasksSuccess(data));
  } catch (error) {
    yield put(actions.loadFromFireStoreError(error));
  }
}


function* saveTask({ payload }) {
	const { data, actionName } = payload;
	try {
		switch (actionName) {
			case 'update':
        const fieldsToOmit = ['key', 'sourceResource'];
				yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${data.key}`, {
					...omit(data, fieldsToOmit),
				});
				break;
			default:
				yield call(rsf.firestore.addDocument, getAccountIdPrefix() + COLLECTION_NAME,  {
					...omit(data, ['key']),
				});
				break;
		} 
    yield put(actions.saveTaskSuccess());
    yield put({ type: actions.LOAD_TASKS, payload: {filterProperty: null}});
		
	} catch (error) {
    console.log("Error");
    console.log(error);
		yield put(actions.saveTaskError(error));
	}
}


function* deleteTask({ payload }) {
	const { data } = payload;
	try {
			if (getAccountIdPrefix() !== ''){
			yield call(rsf.firestore.deleteDocument, getAccountIdPrefix() + COLLECTION_NAME +'/'+ data.key);
			//yield call(rsf.firestore.deleteDocument, COLLECTION_NAME +'/'+ data.key)//Para que se elimine en la cueta principal
			yield put({ type: actions.LOAD_TASKS, payload: { filters: null }});  
      yield put( actions.deleteTaskSuccess());
		}
		else {
			yield put(actions.deleteTaskError());
		}
		yield put(actions.cleanNotificationMessage());
	} catch (error) {
    alert("Error");
		yield put(actions.deleteTaskError(error));
	}
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.LOAD_TASKS_SELECTOR, loadTaskSelector),
    takeEvery(actions.LOAD_TASKS, loadTaks),
    takeEvery(actions.SAVE_TASK, saveTask),
    takeEvery(actions.DELETE_TASK, deleteTask),
  ]);
}







