import { rsf, db} from "@iso/lib/firebase/firebase";
import { call } from "redux-saga/effects";
import {
  convertCollectionsSnapshotToMap,
  convertCollectionsSnapshotToArray,
} from "@iso/lib/firebase/firebase.util";

export function* firestoreCollectionToArray(collectionName, filters) {
  let collectionRef = db.collection(collectionName);
  if (filters) {
    Object.entries(filters).forEach((filter) => {
      const filterData = filter[1];
      collectionRef = collectionRef.where(
        filterData.field,
        filterData.operator,
        filterData.value
      );
    });
  }
  const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
  return yield call(convertCollectionsSnapshotToArray, snapshots);
}

export function* firestoreCollectionToContainsArray(collectionName, filters) {
  let collectionRef = db.collection(collectionName);
  collectionRef = collectionRef.where(
    "cleaning_services_dates",
    "array-contains-any",
    filters
  );
  const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
  return yield call(convertCollectionsSnapshotToArray, snapshots);
}

export function* simpleQueryFirestoreWithFilter(collectionName, filters) {
  let collectionRef = db.collection(collectionName);
  if (filters) {
    Object.entries(filters).forEach((filter) => {
      const filterData = filter[1];
      collectionRef = collectionRef.where(
        filterData.field,
        filterData.operator,
        filterData.value
      );
    });
  }
  const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
  return yield call(convertCollectionsSnapshotToMap, snapshots);
}
export function* simpleQueryFirestoreWithFilterPagination(collectionName, filters, limitRows , paginationDoc, actionPag) {
  let collectionRef = !(paginationDoc === null && filters === null)  ? db.collection(collectionName) : db.collection(collectionName).orderBy("id");
  if ( filters ) {
    let id_filter = false;
    Object.entries( filters ).forEach((filter) => {
      const filterData = filter[1];
      if (filterData.field === 'id') {
        id_filter = true 
      };   
      collectionRef = collectionRef.where(
        filterData.field,
        filterData.operator,
        filterData.value
      ).orderBy(filterData.field);
    });
    collectionRef = (id_filter) ? collectionRef : collectionRef.orderBy('id',"asc");
  }

  if (paginationDoc) {
    if (actionPag === "next") {
      collectionRef = collectionRef.orderBy("id","asc").limit(limitRows).startAfter(paginationDoc); 
    } else if (actionPag === "previous") {
      collectionRef = collectionRef.orderBy("id","desc").limit(limitRows).startAfter(paginationDoc);
    }
  }

  collectionRef = (paginationDoc) ? collectionRef : collectionRef.limit(limitRows);
  const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
  return snapshots.docs.length > 0 ? yield call(convertCollectionsSnapshotToMap, snapshots): {};
}

export function* countDocumentsInCollection(collectionName, filters) {
  let collectionRef = db.collection(collectionName);

  if (filters) {
    Object.entries(filters).forEach(([field, filterData]) => {
      collectionRef = collectionRef.where(
        field,
        filterData.operator,
        filterData.value
      );
    });
  }
  const querySnapshot = yield call([collectionRef, collectionRef.get]);
  return querySnapshot.size;
}

export function* simpleQueryFirestoreWithFilterOrderBy(
  collectionName,
  filters,
  orderBy
) {
  let collectionRef = db.collection(collectionName);
  if (filters) {
    Object.entries(filters).forEach((filter) => {
      const filterData = filter[1];
      collectionRef = collectionRef.where(
        filterData.field,
        filterData.operator,
        filterData.value
      );
    });
  }
  const snapshots = yield call(
    rsf.firestore.getCollection,
    collectionRef.orderBy(orderBy.field, orderBy.type)
  );
  return yield call(convertCollectionsSnapshotToMap, snapshots);
}

export function* simpleQueryFirestore(collectionName) {
  let collectionRef = db.collection(collectionName);
  const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
  return yield call(convertCollectionsSnapshotToMap, snapshots);
}

export function* existInFirestoreDocument(collectionName, filter) {
  let collectionRef = db.collection(collectionName);
  if (filter) {
    collectionRef = collectionRef.where(
      filter.field,
      filter.operator,
      filter.value
    );
  }
  const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
  return yield call(convertCollectionsSnapshotToArray, snapshots);
}

export function* joinCollections(collection, collectionForeign, key, keyJoin) {
  const data = {};
  const formattedCollectionForeign = Object.entries(collectionForeign).map(
    (doc) => {
      return doc[1];
    }
  );
  Object.entries(collection).forEach((doc) => {
    data[doc[0]] = doc[1];
    data[doc[0]][keyJoin] = formattedCollectionForeign.find(
      (docForeig) => docForeig.id.toString() === doc[1][key]
    );
    if (data[doc[0]][keyJoin] === undefined) {
      delete data[doc[0]];
    }
  });

  return yield data;
}

export const addPropertyToCollection = (collectionData, type) => {
  const dataSource = [];
  collectionData.map((collection) => {
    const collectionTemp = collection;
    collectionTemp["type"] = type;
    collectionTemp["order"] =
      type === "departure" ? collection.end_date : collection.start_date;
    return dataSource.push(collectionTemp);
  });
  return dataSource;
};

const getItemsFromCleaning = (items, filters, collection) => {
  const dataSource = [];
  const groupByCreatedAt = items.reduce((group, item) => {
    const { createdAt } = item;
    group[createdAt] = group[createdAt] ?? [];
    group[createdAt].push(item);
    return group;
  }, {});
  Object.entries(groupByCreatedAt).forEach(([key, arrayItems]) => {
    if (filters.includes(parseInt(key))) {
      collection.start_date = parseInt(key);
      const itemNames = [];
      arrayItems.forEach((cleanServiceItem) => {
        itemNames.push(cleanServiceItem.itemName)
      });
      collection.cleaning_services_item = itemNames.join(' , ');
      const bookingCleanService = { ...collection };
      dataSource.push(bookingCleanService);
    }
  });
  return dataSource;
}

export const createCleanServiceRows = (collectionData, filters) => {
  let dataSource = [];
  collectionData.forEach((collection) => {
    const cleanServices = getItemsFromCleaning(collection.cleaning_services_item_list, filters, collection);
    const cleanServicesSpecial = getItemsFromCleaning(collection.cleaning_services_special_item_list, filters, collection);
    dataSource = dataSource.concat(cleanServices);
    dataSource = dataSource.concat(cleanServicesSpecial);
  });
  return dataSource;
};

export const sortJoinCollectionsData = (collectionData) => {
  const sortedCollection = collectionData.sort((a, b) =>
    a.order > b.order ? 1 : b.order > a.order ? -1 : 0
  );
  return sortedCollection;
};

export const chunkFilter = (collectionData, size) => {
  let chunked = [];
  for (let i = 0; i < collectionData.length; i += size) {
    chunked.push(collectionData.slice(i, i + size));
  }
  return chunked;
};


export const simpleQueryFirestoreCheckLastRow = (collectionName,filters, limitRows, paginationDoc) => {
  return new Promise((resolve, reject) => {

    let collectionRef = (filters) ? db.collection(collectionName) : db.collection(collectionName).orderBy("id","asc");
    
    if (filters) {
    
      Object.entries(filters).forEach((filter) => {
        const filterData = filter[1];
        collectionRef = collectionRef.where(
          filterData.field,
          filterData.operator,
          filterData.value
        ).orderBy(filterData.field);
      });
      collectionRef = collectionRef.orderBy('id',"asc");
    }

    collectionRef = collectionRef.limit(limitRows + 1);
    
    if(paginationDoc){
    collectionRef = collectionRef.startAfter(paginationDoc);
    }

    collectionRef = collectionRef.get().then(docs => {

        if (docs.size !== limitRows + 1) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
//FUNCION QUE NO SE UTILIZA
export const simpleQueryFirestoreCheckLPrevRow = (collectionName,filters, limitRows, paginationDoc) => {
  return new Promise((resolve, reject) => {
  
    let collectionRef = filters ? db.collection(collectionName) : db.collection(collectionName).orderBy("id","desc");
    
    if (filters) {
    
      Object.entries(filters).forEach((filter) => {
        const filterData = filter[1];
        collectionRef = collectionRef.where(
          filterData.field,
          filterData.operator,
          filterData.value
        ).orderBy(filterData.field);
      });
      collectionRef = collectionRef.orderBy('id',"desc");
    }

    collectionRef = collectionRef.limit(limitRows + 1);
    
    if(!paginationDoc){
      alert("Primeras filas");
      resolve(true);
    }
     else
    {  
    collectionRef = collectionRef.startAfter(paginationDoc);


    collectionRef = collectionRef.get().then(docs => {
       
       console.log(docs.docs.length);
        if (docs.docs.length >= 3) {
          alert("Hay mas filas");
          resolve(false);
        } else {
          alert("Primeras filas");
          resolve(true);
        }
         
      })
      .catch(error => {
        reject(error);
      });
    }  
  });
}